import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _63bcd318 = () => interopDefault(import('../pages/error/index.vue' /* webpackChunkName: "pages/error/index" */))
const _2c3c1030 = () => interopDefault(import('../pages/sign-out/index.vue' /* webpackChunkName: "pages/sign-out/index" */))
const _45ef5b18 = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _de3de466 = () => interopDefault(import('../pages/users/create/index.vue' /* webpackChunkName: "pages/users/create/index" */))
const _347e4e9a = () => interopDefault(import('../pages/users/_username/index.vue' /* webpackChunkName: "pages/users/_username/index" */))
const _5fd1fa7b = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _414217f0 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/error",
    component: _63bcd318,
    name: "error"
  }, {
    path: "/sign-out",
    component: _2c3c1030,
    name: "sign-out"
  }, {
    path: "/users",
    component: _45ef5b18,
    name: "users"
  }, {
    path: "/users/create",
    component: _de3de466,
    name: "users-create"
  }, {
    path: "/users/:username",
    component: _347e4e9a,
    name: "users-username"
  }, {
    path: "/",
    component: _5fd1fa7b,
    name: "index"
  }, {
    path: "/*",
    component: _414217f0,
    name: "all"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
